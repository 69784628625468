let faq = document.querySelectorAll(".faq, .simple-faq");
console.log(faq);
if(faq.length > 0) {
    faq.forEach((uniqueFaq) => {
        let items = uniqueFaq.querySelectorAll(".item");
        let answers = uniqueFaq.querySelectorAll(".answer");
        let section = uniqueFaq.querySelectorAll('.section');
        let stickyLinks = uniqueFaq.querySelectorAll('.sticky a');

        for(let i=0; i<items.length; i++) {
            items[i].addEventListener('click', function(e){
                if(items[i].classList.contains('active')) {
                    items[i].classList.remove('active')
                    answers[i].style.maxHeight = '0px';
                } else {
                    items[i].classList.add('active')
                    answers[i].style.maxHeight = answers[i].scrollHeight + 'px';
                }
            });
        }

        document.addEventListener("scroll", (event) => {
            var current = "";
            
            section.forEach((section) => {
                if (section.getBoundingClientRect().top <= 250 && section.getBoundingClientRect().top != 0) {
                current = section.getAttribute("id"); 
                }
            });

            stickyLinks.forEach((link) => {
                link.classList.remove("active");
                if (link.classList.contains(current)) {
                    link.classList.add("active");
                }
            });
        });
    });
}