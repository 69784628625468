import './_body-view-height';
import './header';
//import './_slideshow.js';
import './_slider.js';
import './_slider.js';
import './_slider-configuration.js';
import './_testimonial-slider-configuration.js';
import './_load-more-posts.js';
import './_post-archive-filters.js';
import './_post-image-slider-configuration.js';
import './_post-image-slider.js';
import './_post-preview.js';
import './_post-preview-slider-configuration.js';
import './_office-info-slider-configuration.js';
import './_office-info-slider.js';
import './_page-list.js';
import './_referral-list.js';
import './_image-slider-configuration.js';
import './_image-slider.js';
import './_employees.js';
import './_reveal-boxes.js';
import './_partner-slider-configuration.js';
import './_partner.js';
import './_slideshow.js';
import './_single.js';
import './_faq.js';
import './_innline-image-slider-configuration.js';
import './_innline-image-slider.js';

let skipHeader = document.querySelector('a.visually-hidden.skip-to-main');
if(skipHeader != undefined) {
    skipHeader.addEventListener('focusin', function (e) {
        this.classList.add('focused');  
    });
    skipHeader.addEventListener('focusout', function (e) {
        this.classList.remove('focused');  
    });
}


let clientX = -100;
let clientY = -100;
let lastScrollTop = 0;
const footer = document.querySelector("footer");
const menu = document.querySelector("#main-menu .inner");
const header = document.querySelector("header");
const footerHeight = document.querySelector(".footer-height");
const cursor = document.querySelector("#cursor");
const links = document.querySelectorAll(".partial-button-link, .partial-button-underline, p a, .footer-some a, .faq .item, .category-chooser .category, a.partial-button-pluss, a");
const heroMedia = document.querySelector(".hero-media");

let headerLogo = null;
let headerLogoImg = null;

if(header) {
    headerLogo = header.querySelector(".logo");
    headerLogoImg = header.querySelector(".logo picture");
}

if(links) {
    links.forEach(link => {
        link.addEventListener('mouseover', function(e) {
            cursor.classList.add("bigger");
        });

        link.addEventListener('mouseout', function(e){
            cursor.classList.remove("bigger");
        });
    });

}

window.addEventListener("mousemove", e => {
    clientX = e.clientX;
    clientY = e.clientY;
    cursor.style.left = clientX+'px';
    cursor.style.top = clientY+'px';
});

document.addEventListener("mouseenter", e => {
    cursor.classList.remove("hide");
});

document.addEventListener("mouseleave", e => {
    cursor.classList.add("hide");
});

footer.addEventListener("mouseenter", e => {
    cursor.classList.add("pale-green");
});

footer.addEventListener("mouseleave", e => {
    cursor.classList.remove("pale-green");
});

menu.addEventListener("mouseenter", e => {
    cursor.classList.add("pale-green");
});

menu.addEventListener("mouseleave", e => {
    cursor.classList.remove("pale-green");
});

if(heroMedia) {
    heroMedia.addEventListener("mouseenter", e => {
        cursor.classList.add("pale-green");
    });
    
    heroMedia.addEventListener("mouseleave", e => {
        cursor.classList.remove("pale-green");
    });
}

window.addEventListener("load", (event) => {
    if(window.innerWidth > 900) {
        footerHeight.style.height = footer.scrollHeight +'px';
    } else {
        footerHeight.style.height = '0px';
    }
    
    headerLogoImg.classList.add("animate");
});

window.addEventListener("resize", (event) => {
    if(window.innerWidth > 900) {
        footerHeight.style.height = footer.scrollHeight +'px';
    } else {
        footerHeight.style.height = '0px';
    }
});

window.onscroll = function(){

    //Add class to header logo for animation
    if(window.scrollY > 100 || window.location.pathname != '/') {
        header.classList.add("scroll");
    } else {
        header.classList.remove("scroll");
    }

    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
        header.classList.add("scroll-down");
        header.classList.remove("scroll-up");
    } else if (st < lastScrollTop) {
        header.classList.add("scroll-up");
        header.classList.remove("scroll-down");
    } 
    lastScrollTop = st <= 0 ? 0 : st;
    
    //Hide logo in header when footer is visible
    let scrollLength = window.innerHeight + Math.round(window.scrollY);
    let footerHeight = document.querySelectorAll("footer")[0].offsetHeight;

    if(scrollLength >= document.body.offsetHeight - footerHeight) {
        headerLogo.classList.add("hide");
    } else {
        headerLogo.classList.remove("hide");
    }
};

function appendCursorText(cursor) {
    let textBox = document.createElement("p");
    textBox.innerText = 'Go to';
    textBox.classList.add('cursor-text');
    
    cursor.innerHTML = '';
    cursor.append(textBox);
    
    let width = textBox.offsetWidth + 45;
    cursor.style.width = width+'px';
    cursor.style.left = '-60px';

    cursor.classList.add('active');
}

function removeCursorText(cursor) {
    cursor.innerHTML = '';
    cursor.style.width = '18px'
    cursor.classList.remove('active');
}

/*window.addEventListener("load", (event) => {
    let hoverElements = document.querySelectorAll(".hover-link");
    console.log(hoverElements);
    hoverElements.forEach(element => {
        element.addEventListener("mouseenter", e => {
            cursor.classList.add('active-hover');
        });
        element.addEventListener("mouseleave", e => {
            cursor.classList.remove('active-hover');
        });
    });
});*/
