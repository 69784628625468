let postPreview = document.querySelector(".post-preview-manual");
let cursor = document.querySelector("#cursor");
let activeCategory = null; 
let activeCategoryContainer = null;
let activeCategoryText = null; 

if(postPreview) {
    let categoryButtons = postPreview.querySelectorAll("button.category");
    let categoryContainer = postPreview.querySelectorAll(".slider-container-preview");
    let categoryText = postPreview.querySelectorAll(".category-text");
    let categoryTextContainer = postPreview.querySelector(".content");
    let posts = postPreview.querySelectorAll(".tease");

    if(categoryButtons.length == 2 && categoryText[0].scrollHeight > categoryText[1].scrollHeight){
        categoryTextContainer.style.minHeight = categoryText[0].scrollHeight +'px';
    } else {
        categoryTextContainer.style.minHeight = categoryText[1].scrollHeight +'px';
    }

    posts.forEach(post => {
        post.addEventListener('mouseover', function(e) {
            postMouseOver(post);
        });

        post.addEventListener('mouseout', function(e){
            postMouseOut();
        });
    });


    for(let i=0;i<categoryButtons.length;i++) {
        if(categoryButtons[i].classList.contains("active")){
            activeCategory = categoryButtons[i];
            activeCategoryContainer = categoryContainer[i];
            activeCategoryText = categoryText[i];
        }

        categoryButtons[i].addEventListener("click", function(){
            activeCategory.classList.remove('active');
            activeCategoryContainer.classList.remove('active');
            activeCategoryText.classList.remove('active');
            
            categoryButtons[i].classList.add("active");
            categoryContainer[i].classList.add("active");
            categoryText[i].classList.add("active");
            
            activeCategory = categoryButtons[i];
            activeCategoryContainer = categoryContainer[i];
            activeCategoryText = categoryText[i];
        });
    };
}

function postMouseOver(post) {
    let postCategory = post.querySelector('.category');
    let hovertext = postCategory.innerHTML +' ↗';
    appendCursorText(cursor, hovertext);
}

function postMouseOut() {
    removeCursorText(cursor);
}

function appendCursorText(cursor, txt) {
    let textBox = document.createElement("p");
    textBox.innerText = 'Read ' + txt;
    textBox.classList.add('cursor-text');
    
    cursor.innerHTML = '';
    cursor.append(textBox);
    
    let width = textBox.offsetWidth + 45;
    cursor.style.width = width+'px';
    cursor.style.left = '-60px';

    cursor.classList.add('active');
}

function removeCursorText(cursor) {
    cursor.innerHTML = '';
    cursor.style.width = '18px'
    cursor.classList.remove('active');
}