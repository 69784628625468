import Glide from "@glidejs/glide";
let glide = null;

function initPartnerSlider(id) {
    glide = new Glide('#' + id, {
        type: 'carousel', 
        autoplay: 1,
        hoverpause: true,
        animationDuration: 7000,
        animationTimingFunc: 'linear',
        hoverpause: true,
        bound: true,
        startAt: 0,
        gap: 30,
        perView: 5,
        breakpoints: {
            900: {
                perView: 2,
                gap: 0,
            }
        }
    });
    
    glide.mount();
}

export function moveGlide(whichWay) {
    glide.go(whichWay);
}

window.initPartnerSlider = initPartnerSlider;